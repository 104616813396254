export default {
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      img: require("@/assets/pc/common/normal.jpg")
    };
  },
  methods: {
    close() {
      this.$parent.closeNor();
    }
  }
};