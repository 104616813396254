import "core-js/modules/es.array.push.js";
export default {
  props: {
    hasScroll: {
      type: Boolean
    }
  },
  data() {
    return {
      showPopup: false,
      active: "home",
      activeName: [],
      openCollapse: false,
      isFree: false //是否是免费使用页
    };
  },

  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    $route: {
      handler: function (val) {
        let target = val.fullPath;
        target = target.split("/");
        this.active = target[target.length - 1];
        this.$parent.$refs.content.scrollTop = 0;
        this.checkIsFree();
      },
      // 深度观察监听
      deep: true
    }
  },
  //方法集合
  methods: {
    /**
     * @description: 展开弹窗
     * @return {*}
     */
    open() {
      this.showPopup = !this.showPopup;
    },
    /**
     * @description: 解决方案切换
     * @return {*}
     */
    changeCollapse(e) {
      if (e.length > 0) {
        this.openCollapse = true;
      } else {
        this.openCollapse = false;
      }
    },
    /**
     * @description: 判断是否是免费使用页
     * @return {*}
     */
    checkIsFree() {
      const load = this.$route.fullPath;
      if (load.includes("free")) {
        this.isFree = true;
      } else {
        this.isFree = false;
      }
    },
    /**
     * @description: 跳转对应页
     * @return {*}
     */
    navto(e) {
      switch (e) {
        case "index":
          this.$router.push("/pc_index/home");
          break;
        case "normal":
          this.showPopup = false;
          this.$router.push("/pc_index/normal");
          break;
        case "batch":
          this.showPopup = false;
          this.$router.push("/pc_index/batch");
          break;
        case "delivery":
          this.showPopup = false;
          this.$router.push("/pc_index/delivery");
          break;
        case "free":
          this.$router.push("/pc_index/free");
          break;
        case "aboutUs":
          this.$router.push("/pc_index/aboutUs");
          break;
        default:
          break;
      }
      this.showPopup = false;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    const load = this.$route.fullPath;
    if (load.includes("normal")) {
      this.active = "normal";
      this.isFree = false;
    } else if (load.includes("batch")) {
      this.active = "batch";
      this.isFree = false;
    } else if (load.includes("delivery")) {
      this.active = "delivery";
      this.isFree = false;
    } else if (load.includes("aboutUs")) {
      this.active = "aboutUs";
      this.isFree = false;
    } else if (load.includes("free")) {
      this.active = "free";
      this.isFree = true;
    } else {
      this.active = "home";
      this.isFree = false;
    }
  }
};