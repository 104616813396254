import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      hasScroll: false,
      showPopup: false,
      list: [{
        name: "normal",
        title: "通用场景方案",
        text: "适用于大多数农批档口的解决方案",
        img: require("@/assets/pc/common/nav1.jpg")
      }, {
        name: "batch",
        title: "档口批发方案",
        text: "适用于一批档口的解决方案",
        img: require("@/assets/pc/common/nav2.jpg")
      }, {
        name: "delivery",
        title: "分拣配送方案",
        text: "适用于分拣配送的解决方案",
        img: require("@/assets/pc/common/nav3.jpg")
      }],
      active: "home",
      isFree: false //是否是免费使用页
    };
  },

  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    $route: {
      handler: function (val) {
        let target = val.fullPath;
        target = target.split("/");
        this.active = target[target.length - 1];
        this.checkIsFree();
      },
      // 深度观察监听
      deep: true
    }
  },
  //方法集合
  methods: {
    /**
     * @description: 判断是否是免费使用页
     * @return {*}
     */
    checkIsFree() {
      const load = this.$route.fullPath;
      if (load.includes("free")) {
        this.isFree = true;
      } else {
        this.isFree = false;
      }
    },
    /**
     * @description: 页面滑动
     * @return {*}
     */
    scrollToTop() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop != 0) {
        this.hasScroll = true;
      } else {
        this.hasScroll = false;
      }
    },
    /**
     * @description: 解决方案鼠标移入
     * @return {*}
     */
    mouseenter() {
      this.showPopup = true;
    },
    /**
     * @description: 解决方案鼠标移出
     * @return {*}
     */
    mouseLeave() {
      this.showPopup = false;
    },
    /**
     * @description: 跳转对应页
     * @return {*}
     */
    navto(e) {
      switch (e) {
        case "index":
          this.$router.push("/pc_index/home");
          break;
        case "normal":
          this.showPopup = false;
          this.$router.push("/pc_index/normal");
          break;
        case "batch":
          this.showPopup = false;
          this.$router.push("/pc_index/batch");
          break;
        case "delivery":
          this.showPopup = false;
          this.$router.push("/pc_index/delivery");
          break;
        case "free":
          this.$router.push("/pc_index/free");
          break;
        case "aboutUs":
          this.$router.push("/pc_index/aboutUs");
          break;
        default:
          break;
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    const load = this.$route.fullPath;
    if (load.includes("normal")) {
      this.active = "normal";
      this.isFree = false;
    } else if (load.includes("batch")) {
      this.active = "batch";
      this.isFree = false;
    } else if (load.includes("delivery")) {
      this.active = "delivery";
      this.isFree = false;
    } else if (load.includes("aboutUs")) {
      this.active = "aboutUs";
      this.isFree = false;
    } else if (load.includes("free")) {
      this.active = "free";
      this.isFree = true;
    } else {
      this.active = "home";
      this.isFree = false;
    }
  },
  beforeRouteLeave(to, form, next) {
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  }
};