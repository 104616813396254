import Navbar from "./components/Navbar.vue";
// import index from "./views/aboutUs/index.vue";
import PageFooter from "./components/PageFooter.vue";
export default {
  name: "mLayout",
  components: {
    Navbar,
    // index,
    PageFooter
  },
  computed: {},
  data() {
    return {
      hasScroll: false
    };
  },
  methods: {
    contentScroll() {
      if (this.$refs.content.scrollTop != 0) {
        if (!this.hasScroll) this.hasScroll = true;
      } else {
        this.hasScroll = false;
      }
    }
  }
};