import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import { Popup, Collapse, CollapseItem, Overlay, Picker } from "vant";
import "vant/lib/index.css";
import fetchJsonp from "fetch-jsonp";
import "@/assets/font/font.css";
import lazyPlugin from "vue3-lazy";
//根据不同设备跳转不同页面
router.beforeEach((to, from, next) => {
  let load = to.fullPath;
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    if (load.includes("pc_index")) {
      load = load.replace("pc_index", "m_index");
      next(load);
    } else {
      next();
    }
  } else if (load.includes("m_index")) {
    load = load.replace("m_index", "pc_index");
    next(load);
  } else {
    next();
  }
});

const app = createApp(App);
app.use(router);
app.use(Popup);
app.use(Collapse);
app.use(CollapseItem);
app.use(Overlay);
app.use(Picker);
app.config.globalProperties.fetchJsonp = fetchJsonp;
app.use(lazyPlugin, {
  error: require("@/assets/phone/common/close.png"),
});
app.mount("#app");
