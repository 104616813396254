import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-wrapper"
};
const _hoisted_2 = {
  class: "header"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Navbar = _resolveComponent("Navbar");
  const _component_router_view = _resolveComponent("router-view");
  const _component_PageFooter = _resolveComponent("PageFooter");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Navbar, {
    hasScroll: $data.hasScroll
  }, null, 8, ["hasScroll"])]), _createElementVNode("div", {
    class: "m_content",
    ref: "content",
    onScroll: _cache[0] || (_cache[0] = $event => $options.contentScroll($event))
  }, [_createVNode(_component_router_view), _createVNode(_component_PageFooter)], 544)])]);
}