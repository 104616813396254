import Navbar from "./components/Navbar.vue";
// import index from "./views/aboutUs/index.vue";
import PageFooter from "./components/PageFooter.vue";
export default {
  name: "PcLayout",
  components: {
    Navbar,
    // index,
    PageFooter
  },
  computed: {},
  methods: {},
  mounted() {
    // 将callbackFun方法绑定到window对象
    window["callback"] = data => {
      console.log(data);
    };
  }
};