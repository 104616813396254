import "core-js/modules/es.array.push.js";
import Normal from "./normal.vue";
import Batch from "./batch.vue";
export default {
  data() {
    return {
      showNormal: false,
      showBatch: false,
      qywxImg: require("@/assets/pc/common/qywx.png"),
      gzhImg: require("@/assets/pc/common/gzh.png")
    };
  },
  components: {
    Normal,
    Batch
  },
  //方法集合
  methods: {
    openPopup(e) {
      if (e == "normal") {
        this.openNormal();
      } else if (e == "batch") {
        this.openBatch();
      } else {
        window.location.href = "http://appxz.hnfresh.com/l1v4";
      }
    },
    openNormal() {
      this.showNormal = true;
    },
    openBatch() {
      this.showBatch = true;
    },
    closeNor() {
      this.showNormal = false;
    },
    closeBatch() {
      this.showBatch = false;
    },
    navTo(e) {
      switch (e) {
        case "normal":
          this.$router.push("/pc_index/normal");
          break;
        case "batch":
          this.$router.push("/pc_index/batch");
          break;
        case "delivery":
          this.$router.push("/pc_index/delivery");
          break;
        case "aboutUs":
          this.$router.push("/pc_index/aboutUs");
          break;
        case "free":
          this.$router.push("/pc_index/free");
          break;
      }
    }
  }
};