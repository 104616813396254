import { createRouter, createWebHashHistory } from "vue-router";
const routerHistory = createWebHashHistory();
/* Layout */
import PcLayout from "@/PcLayout";
import MLayout from "@/MLayout";

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: "",
    redirect: "pc_index/home",
  },
  {
    path: "/pc_index",
    name: "pc首页",
    component: PcLayout,
    children: [
      {
        path: "/pc_index/home",
        name: "home",
        component: () =>
          require.ensure([], (require) =>
            require(`@/PcLayout/views/index/index`)
          ),
      },
      {
        path: "/pc_index/normal",
        name: "normal",
        component: () =>
          require.ensure([], (require) =>
            require(`@/PcLayout/views/normal/index`)
          ),
      },
      {
        path: "/pc_index/batch",
        name: "batch",
        component: () =>
          require.ensure([], (require) =>
            require(`@/PcLayout/views/batch/index`)
          ),
      },
      {
        path: "/pc_index/delivery",
        name: "delivery",
        component: () =>
          require.ensure([], (require) =>
            require(`@/PcLayout/views/delivery/index`)
          ),
      },
      {
        path: "/pc_index/aboutUs",
        name: "aboutUs",
        component: () =>
          require.ensure([], (require) =>
            require(`@/PcLayout/views/aboutUs/index`)
          ),
      },
      {
        path: "/pc_index/free",
        name: "free",
        component: () =>
          require.ensure([], (require) =>
            require(`@/PcLayout/views/free/index`)
          ),
      },
    ],
  },
  {
    path: "/m_index",
    name: "m首页",
    component: MLayout,
    children: [
      {
        path: "/m_index/home",
        name: "Mhome",
        component: () =>
          require.ensure([], (require) =>
            require(`@/MLayout/views/index/index`)
          ),
      },
      {
        path: "/m_index/normal",
        name: "Mnormal",
        component: () =>
          require.ensure([], (require) =>
            require(`@/MLayout/views/normal/index`)
          ),
      },
      {
        path: "/m_index/batch",
        name: "Mbatch",
        component: () =>
          require.ensure([], (require) =>
            require(`@/MLayout/views/batch/index`)
          ),
      },
      {
        path: "/m_index/delivery",
        name: "Mdelivery",
        component: () =>
          require.ensure([], (require) =>
            require(`@/MLayout/views/delivery/index`)
          ),
      },
      {
        path: "/m_index/aboutUs",
        name: "MaboutUs",
        component: () =>
          require.ensure([], (require) =>
            require(`@/MLayout/views/aboutUs/index`)
          ),
      },
      {
        path: "/m_index/free",
        name: "Mfree",
        component: () =>
          require.ensure([], (require) =>
            require(`@/MLayout/views/free/index`)
          ),
      },
    ],
  },
];

const router = createRouter({
  mode: "history",
  history: routerHistory,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes: constantRoutes,
});

export default router;
